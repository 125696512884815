<template>
  <div class="container">
    <div class="right_cont">
      <div class="title flex_center_start_box">
        <div class="title_txt flex_center"><img src="../../assets/img/detail/title_ic3.png" />Company Profile</div>
      </div>
      <div class="right_cont_box">
        <div class="group profile">
          <div class="item">
            <label class="lab">Co. Name:</label>
            <div class="txt">{{ obj.coName ? obj.coName : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Native Name:</label>
            <div class="txt">{{ obj.nativeName ? obj.nativeName : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Co. ID:</label>
            <div class="txt">{{ obj.coId ? obj.coId : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">VAT No:</label>
            <div class="txt">{{ obj.vatNo ? obj.vatNo : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Registry:</label>
            <div class="txt">{{ obj.registry ? obj.registry : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Reg.Capital:</label>
            <div class="txt">{{ obj.regCapital ? obj.regCapital : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Paid Up Capital:</label>
            <div class="txt">{{ obj.upCapital ? obj.upCapital : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Date Founded:</label>
            <div class="txt">{{ obj.dateFound ? obj.dateFound : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Date Incorporated:</label>
            <div class="txt">{{ obj.dateIncor ? obj.dateIncor : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Registered Address:</label>
            <div v-show="!showTranslate" class="txt" style="width: 930px;">{{obj.regStreet}} <span v-if=" obj.regAddress">,</span> {{ obj.regAddress }}  <span v-if="obj.regCountry">,</span> {{ obj.regCountry }}</div>
            <!-- {{ obj.regAddress ? obj.regAddress : '-' }} -->
            <div v-show="showTranslate" class="txt" style="width: 930px;" >{{obj.regStreetCn}}  <span v-if=" obj.regAddressCn">,</span> {{ obj.regAddressCn }}  <span v-if=" obj.regCountryCn">,</span> {{ obj.regCountryCn }}</div>
            <!-- {{ obj.regAddressCn ? obj.regAddressCn : '-' }} -->
          </div>
          <div class="item">
            <label class="lab">Operating Address:</label>
            <div v-show="!showTranslate" class="txt"> {{obj.opeStreet}} <span v-if="obj?.opeAddress ">,</span> {{ obj.opeAddress }} <span v-if="obj?.opeCountry ">,</span> {{ obj.opeCountry }}</div>
            <!-- {{ obj.opeAddress ? obj.opeAddress : '-' }} -->
            <div v-show="showTranslate" class="txt" >{{obj.opeStreetCn}} <span v-if="obj?.opeAddressCn ">,</span> {{ obj.opeAddressCn }} <span v-if="obj?.opeCountryCn ">,</span> {{ obj.opeCountryCn }}</div>
            <!-- {{ obj.opeAddressCn ? obj.opeAddressCn : '-' }} -->
          </div>
          <div class="item">
            <label class="lab">City/Country:</label>
            <div v-show="!showTranslate" class="txt">{{ obj.cityCountry ? obj.cityCountry : '-' }}</div>
            <div v-show="showTranslate" class="txt">{{ obj.cityCountryCn ? obj.cityCountryCn : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Legal Status:</label>
            <div class="txt">{{ obj.legalStatus ? obj.legalStatus : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Listed:</label>
            <div class="txt">{{ obj.listed ? obj.listed : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Last Update:</label>
            <div class="txt">{{ obj.lastUpdate ? obj.lastUpdate : '-' }}</div>
          </div>
          <div class="item">
            <label class="lab">Data Source:</label>
            <div class="txt"  style="text-decoration: none;color: #1290c9; cursor: pointer;"  v-for="(item,index) in obj.dataSource" :key="index" @click="setid(item.website)">{{ item.register}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { getCompanyProfile } from '@/api/companyApi.js'
import crypto from '@/utils/crypto'
export default {
  data() {
    return {
      obj: {}
    }
  },
  computed: {
        //index传值 是否翻译
        showTranslate: {
            get() {
              // console.log(`this.$parent.indexIsTranslate`, this.$parent.indexIsTranslate);
                return this.$parent.indexIsTranslate;
            },
            set() {},
        },
        curPagePower: {
            get() {
                return JSON.parse(this.$parent.powerToPage);
            },
            set() {},
        },
    },
  methods: {
    setid(row) {
if(row.includes('http')){
    let routeData=row
    window.open(routeData, '_blank');
}else{
    let routeData='http://'+row
window.open(routeData, '_blank');
}

},
  },
  created() {
    const id3a = this.$route.query.id3a
    const companyCountry = this.$route.query.companyCountry
    let params ='id3a=' + id3a + '&companyCountry=' + companyCountry
    // let params = 'id3a=840309097305&companyCountry=US'
    getCompanyProfile(params).then(result => {
      let data = JSON.parse(crypto.decrypt(result))
      if (data && data.code && data.data) {
        this.obj = data.data
      }
      console.log('result- getCompanyProfile', data.data)
    })
  }
}
</script>
<style scoped>
.cont_m {
  margin-top: 30px;
  margin-bottom: 80px;
}
.right_cont {
  margin-top: 60px;
}
.title {
  margin-bottom: 10px;
}
.title_txt {
  font-size: 16px;
  font-family: 'Arial Bold';
}
.title_txt img {
  width: 20px;
  margin-right: 8px;
}
.group.profile {
  padding: 0 10px;
}
.profile .item {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e7eaed;
}
.profile .item:first-child {
  padding-top: 10px;
}
.profile .item:last-child {
  padding-bottom: 10px;
  border-width: 0;
}
.profile .item .lab {
  width: 148px;
  font-size: 16px;
  color: #8497ab;
}
.profile .item .txt {
  margin-left: 40px;
  color: #022955;
  font-size: 16px;
}
@media (max-width: 821px) {

.profile .item {
 flex-wrap: wrap;
 padding-bottom: 10px !important;
 padding-top: 10px !important;
}
.profile .item .lab{
  width: 100%;
  font-size: 14px !important;
  color: #022955 !important;
}
.profile .item .txt{
  margin-left: 0px !important;
  margin-top: 10px;
  font-size: 12px !important;
  color: #8497ab !important;
}
.right_cont{
  margin-top: 20px !important;
}

}
</style>
